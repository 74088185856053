







































































import { Component, Ref, Vue } from 'vue-property-decorator'
import { ROLES } from '@/model/Role'
import { Utils } from '../../utils/Utils'
import router from '@/router'

@Component({
  components: {},
})
export default class ViewLogin extends Vue {
  username = ''
  password = ''

  @Ref('usernameEl') usernameEl: any
  @Ref('passwordEl') passwordEl: any

  validate(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login()
    }
  }

  goTo() {
    router.push({ name: 'Register' })
  }

  updateUsername(value: string) {
    console.log('updateUsername', value)
    this.username = value
  }

  updatePassword(value: string) {
    console.log('updatePassword', value)
    this.password = value
  }

  login() {
    const username = this.usernameEl.value
    const password = this.passwordEl.value

    console.log(username, password)

    const valid = Utils.checkInputsValidity('.login-form dig-input-text')
    if (!valid) {
      return this.$store.dispatch('toaster/toast', {
        type: 'warning',
        message: `Le formulaire est invalide`,
      })
    }

    this.$store.dispatch('login', { username, password }).then(json => {
      this.$store.dispatch('toaster/toast', json)

      if (json.success) {
        if (this.$route.params.backurl) {
          this.$router.push({ path: this.$route.params.backurl })
        } else {
          this.$router.push({ name: 'Home' })
        }

        if (this.$store.getters.loggedUser.role === ROLES.USER) {
          this.$store.dispatch('getProjects').then(() => {
            this.$store.dispatch('nav/refresh', this.$route.path)
          })
        } else if (this.$store.getters.isAdmin) {
          this.$store.dispatch('getUsers').then(() => {
            this.$store.dispatch('nav/refresh', this.$route.path)
          })
        }
      }
    })
  }
}
